import React from 'react';
import { ImageSourcePropType } from 'react-native';
//We need to import all icons as components for the mobile version
import StarIcon from './svg/star-icon.svg';
import StarIconDark from './svg/star-icon-dark.svg';

import StarIconFill from './svg/star-icon-fill.svg';
import StarIconFillDark from './svg/star-icon-fill-dark.svg';

import StarIconPrimary from './svg/star-icon-primary.svg';
import StartIconPrimaryDark from './svg/star-icon-primary-dark.svg';

import StarIconFillPrimary from './svg/star-icon-fill-primary.svg';
import StarIconFillPrimaryDark from './svg/star-icon-fill-primary-dark.svg';

import BookmarkIcon from './svg/bookmark.svg';
import BookmarkFilledIcon from './svg/bookmark-filled.svg';
import BookmarkFilledIconDark from './svg/bookmark-filled-dark.svg';

import ArrowLeftIcon from './svg/arrow-left.svg';
import ArrowLeftIconDark from './svg/arrow-left-dark.svg';

import DoubleArrowLeftIcon from './svg/double-arrow-left.svg';
import DoubleArrowLeftIconDark from './svg/double-arrow-left-dark.svg';

import NotificationOnIcon from './svg/notification-on-icon.svg';
import NotificationOnIconDark from './svg/notification-on-icon-dark.svg';

import NotificationDisabledIcon from './svg/notification-disabled-icon.svg';
import NotificationDisabledIconDark from './svg/notification-disabled-icon-dark.svg';

import DateIcon from './svg/date-icon.svg';
import DateIconDark from './svg/date-icon-dark.svg';

import HomeIcon from './svg/home-icon.svg';
import HomeIconDark from './svg/home-icon-dark.svg';

import NewsIcon from './svg/hir-icon.svg';
import NewsIconDark from './svg/hir-icon-dark.svg';

import AppsIcon from './svg/apps-icon.svg';
import AppsIconDark from './svg/apps-icon-dark.svg';

import NotificationIcon from './svg/notification-icon.svg';
import NotificationIconDark from './svg/notification-icon-dark.svg';

import ArrowRightIcon from './svg/arrow-right-icon.svg';
import ArrowRightIconDark from './svg/arrow-right-icon-dark.svg';

import ArrowSimpleLeftIcon from './svg/arrow-simple-left-icon.svg';
import ArrowSimpleLeftIconDark from './svg/arrow-simple-left-icon-dark.svg';

import ArrowSimpleRightIcon from './svg/arrow-simple-right-icon.svg';
import ArrowSimpleRightIconDark from './svg/arrow-simple-right-icon-dark.svg';

import Logo from './svg/logo.svg';
import LogoDark from './svg/logo-dark.svg';

import CloseIcon from './svg/close-icon.svg';
import CloseIconDark from './svg/close-icon-dark.svg';

import BookmarkBorderIcon from './svg/bookmark-border-icon.svg';
import BookmarkBorderIconDark from './svg/bookmark-border-icon-dark.svg';

import DragIndicatorIcon from './svg/drag-indicator.svg';
import DragIndicatorIconDark from './svg/drag-indicator-dark.svg';

import NotificationNewIcon from './svg/notification-new-icon.svg';
import NotificationNewIconDark from './svg/notification-new-icon-dark.svg';

import SearchIcon from './svg/search-icon.svg';
import SearchIconDark from './svg/search-icon-dark.svg';

import DefaultProfilePicture from './svg/default-profile-photo.svg';
import DefaultProfilePictureDark from './svg/default-profile-photo-dark.svg';

import ChevronUpIcon from './svg/chevron-up-icon.svg';
import ChevronUpIconDark from './svg/chevron-up-icon-dark.svg';

import ChevronDownIcon from './svg/chevron-down-icon.svg';
import ChevronDownIconDark from './svg/chevron-down-icon-dark.svg';

import ChevronDownSmallIcon from './svg/chevron-down-small-icon.svg';
import ChevronDownSmallIconDark from './svg/chevron-down-small-icon-dark.svg';

import ChevronUpSmallIcon from './svg/chevron-up-small-icon.svg';
import ChevronUpSmallIconDark from './svg/chevron-up-small-icon-dark.svg';

import CalendarCardIcon from './svg/calendar-card-icon.svg';
import CalendarCardIconDark from './svg/calendar-card-icon-dark.svg';

import CalendarIcon from './svg/calendar-icon.svg';
import CalendarIconDark from './svg/calendar-icon-dark.svg';

import CalendarIconFilled from './svg/calendar-icon-filled.svg';
import CalendarIconDarkFilled from './svg/calendar-icon-dark-filled.svg';

import PinIcon from './svg/pin-icon.svg';
import PinIconDark from './svg/pin-icon-dark.svg';

import PinIconPrimary from './svg/pin-icon-primary.svg';
import PinIconPrimaryDark from './svg/pin-icon-primary-dark.svg';

import PastIcon from './svg/past-icon.svg';
import PastIconDark from './svg/past-icon-dark.svg';

import NewspaperIcon from './svg/newspaper.svg';
import NewspaperIconDark from './svg/newspaper-dark.svg';

import ExtremeEventCalendarIcon from './svg/extreme-event-calendar-icon.svg';
import ExtremeEventCalendarIconDark from './svg/extreme-event-calendar-icon-dark.svg';

import AlertIcon from './svg/alert.svg';
import AlertIconLight from './svg/alert-icon.svg';

import AlertReadIcon from './svg/alert-read-light.svg';
import AlertReadIconDark from './svg/alert-read-dark.svg';

import EditIcon from './svg/edit-icon.svg';
import EditIconDark from './svg/edit-icon-dark.svg';

import DeleteIcon from './svg/delete-icon.svg';
import DeleteIconDark from './svg/delete-icon-dark.svg';

import LockIcon from './svg/lock-icon.svg';
import LockIconDark from './svg/lock-icon-dark.svg';

import CheckmarkIcon from './svg/checkmark-icon.svg';
import CheckmarkIconDark from './svg/checkmark-icon-dark.svg';

import GraduationHatIcon from './svg/graduation-hat-icon.svg';
import GraduationHatIconDark from './svg/graduation-hat-icon-dark.svg';

import GirlCommentIcon from './svg/girl-comment-icon.svg';
import GirlCommentIconDark from './svg/girl-comment-icon-dark.svg';

import BoySettingsIcon from './svg/boy-settings-icon.svg';
import BoySettingsIconDark from './svg/boy-settings-icon-dark.svg';

import ChartsIcon from './svg/charts-icon.svg';
import ChartsIconDark from './svg/charts-icon-dark.svg';

import FileIcon from './svg/file-icon.svg';
import FileIconDark from './svg/file-icon-dark.svg';

import RunningShoeIcon from './svg/running-shoe-icon.svg';
import RunningShoeIconDark from './svg/running-shoe-icon-dark.svg';

import LocationIcon from './svg/location-icon.svg';
import LocationIconDark from './svg/location-icon-dark.svg';

import UploadIcon from './svg/upload-icon.svg';
import UploadIconDark from './svg/upload-icon-dark.svg';

import FilmIconPrimary from './svg/film-icon-primary.svg';
import FilmIconPrimaryDark from './svg/film-icon-primary-dark.svg';

import TickSuccessIcon from './svg/tick-success.svg';
import TickSuccessIconDark from './svg/tick-success-dark.svg';

import ConstellationIcon from './svg/constellation-icon.svg';
import ConstellationIconDark from './svg/constellation-icon-dark.svg';

import BugIcon from './svg/bug.svg';
import BugIconDark from './svg/bug-dark.svg';

import CanteenIcon from './svg/canteen-icon.svg';
import CanteenIconDark from './svg/canteen-icon-dark.svg';

import NeptunIcon from './svg/neptun-icon.svg';
import NeptunIconDark from './svg/neptun-icon-dark.svg';

import MomeRunIcon from './svg/momerun-icon.svg';
import MomeRunIconDark from './svg/momerun-icon-dark.svg';

import SpeechBubbleIcon from './svg/speech-bubble-icon.svg';
import SpeechBubbleIconDark from './svg/speech-bubble-icon-dark.svg';

import HelpIcon from './svg/help-icon.svg';
import HelpIconDark from './svg/help-icon-dark.svg';

import NexonIcon from './svg/nexon-icon.svg';
import NexonIconDark from './svg/nexon-icon-dark.svg';

import SchoolIcon from './svg/school-icon.svg';
import SchoolIconDark from './svg/school-icon-dark.svg';

interface WebIcon {
  light: ImageSourcePropType;
  dark: ImageSourcePropType;
}

interface MobileIcon {
  light: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  dark: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export interface SvgIcon {
  web: WebIcon;
  mobile: MobileIcon;
}

interface SvgIcons {
  [key: string]: SvgIcon;
}

//Deciding the color of the icons based on the theme
const svgIcons: SvgIcons = {
  starIcon: {
    web: {
      light: require('./svg/star-icon.svg'),
      dark: require('./svg/star-icon-dark.svg'),
    },
    mobile: {
      light: StarIcon,
      dark: StarIconDark,
    },
  },
  starIconFill: {
    web: {
      light: require('./svg/star-icon-fill.svg'),
      dark: require('./svg/star-icon-fill-dark.svg'),
    },
    mobile: {
      light: StarIconFill,
      dark: StarIconFillDark,
    },
  },
  starIconPrimary: {
    web: {
      light: require('./svg/star-icon-primary.svg'),
      dark: require('./svg/star-icon-primary-dark.svg'),
    },
    mobile: {
      light: StarIconPrimary,
      dark: StartIconPrimaryDark,
    },
  },
  starIconFillPrimary: {
    web: {
      light: require('./svg/star-icon-fill-primary.svg'),
      dark: require('./svg/star-icon-fill-primary-dark.svg'),
    },
    mobile: {
      light: StarIconFillPrimary,
      dark: StarIconFillPrimaryDark,
    },
  },
  notificationOnIcon: {
    web: {
      light: require('./svg/notification-on-icon-dark.svg'),
      dark: require('./svg/notification-on-icon.svg'),
    },
    mobile: {
      light: NotificationOnIconDark,
      dark: NotificationOnIcon,
    },
  },
  notificationOffIcon: {
    web: {
      light: require('./svg/notification-icon.svg'),
      dark: require('./svg/notification-icon-dark.svg'),
    },
    mobile: {
      light: NotificationIcon,
      dark: NotificationIconDark,
    },
  },
  notificationDisabledIcon: {
    web: {
      light: require('./svg/notification-disabled-icon.svg'),
      dark: require('./svg/notification-disabled-icon-dark.svg'),
    },
    mobile: {
      light: NotificationDisabledIcon,
      dark: NotificationDisabledIconDark,
    },
  },
  EventsScreen: {
    web: {
      light: require('./svg/date-icon.svg'),
      dark: require('./svg/date-icon-dark.svg'),
    },
    mobile: {
      light: DateIcon,
      dark: DateIconDark,
    },
  },
  HomeScreen: {
    web: {
      light: require('./svg/home-icon.svg'),
      dark: require('./svg/home-icon-dark.svg'),
    },
    mobile: {
      light: HomeIcon,
      dark: HomeIconDark,
    },
  },
  ArticlesScreen: {
    web: {
      light: require('./svg/hir-icon.svg'),
      dark: require('./svg/hir-icon-dark.svg'),
    },
    mobile: {
      light: NewsIcon,
      dark: NewsIconDark,
    },
  },
  PlatformsScreen: {
    web: {
      light: require('./svg/apps-icon.svg'),
      dark: require('./svg/apps-icon-dark.svg'),
    },
    mobile: {
      light: AppsIcon,
      dark: AppsIconDark,
    },
  },
  NotificationsScreen: {
    web: {
      light: require('./svg/notification-icon.svg'),
      dark: require('./svg/notification-icon-dark.svg'),
    },
    mobile: {
      light: NotificationIcon,
      dark: NotificationIconDark,
    },
  },
  arrowRightIcon: {
    web: {
      light: require('./svg/arrow-right-icon.svg'),
      dark: require('./svg/arrow-right-icon-dark.svg'),
    },
    mobile: {
      light: ArrowRightIcon,
      dark: ArrowRightIconDark,
    },
  },
  arrowLeftIcon: {
    web: {
      light: require('./svg/arrow-left.svg'),
      dark: require('./svg/arrow-left-dark.svg'),
    },
    mobile: {
      light: ArrowLeftIcon,
      dark: ArrowLeftIconDark,
    },
  },
  doubleArrowLeftIcon: {
    web: {
      light: require('./svg/double-arrow-left.svg'),
      dark: require('./svg/double-arrow-left-dark.svg'),
    },
    mobile: {
      light: DoubleArrowLeftIcon,
      dark: DoubleArrowLeftIconDark,
    },
  },
  logo: {
    web: {
      light: require('./svg/logo.svg'),
      dark: require('./svg/logo-dark.svg'),
    },
    mobile: {
      light: Logo,
      dark: LogoDark,
    },
  },
  arrowSimpleLeftIcon: {
    web: {
      light: require('./svg/arrow-simple-left-icon.svg'),
      dark: require('./svg/arrow-simple-left-icon-dark.svg'),
    },
    mobile: {
      light: ArrowSimpleLeftIcon,
      dark: ArrowSimpleLeftIconDark,
    },
  },
  arrowSimpleRightIcon: {
    web: {
      light: require('./svg/arrow-simple-right-icon.svg'),
      dark: require('./svg/arrow-simple-right-icon-dark.svg'),
    },
    mobile: {
      light: ArrowSimpleRightIcon,
      dark: ArrowSimpleRightIconDark,
    },
  },
  bookmarkIcon: {
    web: {
      light: require('./svg/bookmark.svg'),
      dark: require('./svg/bookmark.svg'),
    },
    mobile: {
      light: BookmarkIcon,
      dark: BookmarkIcon,
    },
  },
  bookmarkFilledIcon: {
    web: {
      light: require('./svg/bookmark-filled.svg'),
      dark: require('./svg/bookmark-filled-dark.svg'),
    },
    mobile: {
      light: BookmarkFilledIcon,
      dark: BookmarkFilledIconDark,
    },
  },
  closeIcon: {
    web: {
      light: require('./svg/close-icon.svg'),
      dark: require('./svg/close-icon-dark.svg'),
    },
    mobile: {
      light: CloseIcon,
      dark: CloseIconDark,
    },
  },
  bookmarkBorder: {
    web: {
      light: require('./svg/bookmark-border-icon.svg'),
      dark: require('./svg/bookmark-border-icon-dark.svg'),
    },
    mobile: {
      light: BookmarkBorderIcon,
      dark: BookmarkBorderIconDark,
    },
  },
  dragIndicator: {
    web: {
      light: require('./svg/drag-indicator.svg'),
      dark: require('./svg/drag-indicator-dark.svg'),
    },
    mobile: {
      light: DragIndicatorIcon,
      dark: DragIndicatorIconDark,
    },
  },
  notificationNewIcon: {
    web: {
      light: require('./svg/notification-new-icon.svg'),
      dark: require('./svg/notification-new-icon-dark.svg'),
    },
    mobile: {
      light: NotificationNewIcon,
      dark: NotificationNewIconDark,
    },
  },
  searchIcon: {
    web: {
      light: require('./svg/search-icon.svg'),
      dark: require('./svg/search-icon-dark.svg'),
    },
    mobile: {
      light: SearchIcon,
      dark: SearchIconDark,
    },
  },
  defaultProfilePicture: {
    web: {
      light: require('./svg/default-profile-photo.svg'),
      dark: require('./svg/default-profile-photo-dark.svg'),
    },
    mobile: {
      light: DefaultProfilePicture,
      dark: DefaultProfilePictureDark,
    },
  },
  chevronDownIcon: {
    web: {
      light: require('./svg/chevron-down-icon.svg'),
      dark: require('./svg/chevron-down-icon-dark.svg'),
    },
    mobile: {
      light: ChevronDownIcon,
      dark: ChevronDownIconDark,
    },
  },
  chevronDownSmallIcon: {
    web: {
      light: require('./svg/chevron-down-small-icon.svg'),
      dark: require('./svg/chevron-down-small-icon-dark.svg'),
    },
    mobile: {
      light: ChevronDownSmallIcon,
      dark: ChevronDownSmallIconDark,
    },
  },
  chevronUpIcon: {
    web: {
      light: require('./svg/chevron-up-icon.svg'),
      dark: require('./svg/chevron-up-icon-dark.svg'),
    },
    mobile: {
      light: ChevronUpIcon,
      dark: ChevronUpIconDark,
    },
  },
  chevronUpSmallIcon: {
    web: {
      light: require('./svg/chevron-up-small-icon.svg'),
      dark: require('./svg/chevron-up-small-icon-dark.svg'),
    },
    mobile: {
      light: ChevronUpSmallIcon,
      dark: ChevronUpSmallIconDark,
    },
  },
  calendarCardIcon: {
    web: {
      light: require('./svg/calendar-card-icon.svg'),
      dark: require('./svg/calendar-card-icon-dark.svg'),
    },
    mobile: {
      light: CalendarCardIcon,
      dark: CalendarCardIconDark,
    },
  },
  calendarIcon: {
    web: {
      light: require('./svg/calendar-icon.svg'),
      dark: require('./svg/calendar-icon-dark.svg'),
    },
    mobile: {
      light: CalendarIcon,
      dark: CalendarIconDark,
    },
  },
  calendarIconFilled: {
    web: {
      light: require('./svg/calendar-icon-filled.svg'),
      dark: require('./svg/calendar-icon-dark-filled.svg'),
    },
    mobile: {
      light: CalendarIconFilled,
      dark: CalendarIconDarkFilled,
    },
  },
  pinIcon: {
    web: {
      light: require('./svg/pin-icon.svg'),
      dark: require('./svg/pin-icon-dark.svg'),
    },
    mobile: {
      light: PinIcon,
      dark: PinIconDark,
    },
  },
  pinIconPrimary: {
    web: {
      light: require('./svg/pin-icon-primary.svg'),
      dark: require('./svg/pin-icon-primary-dark.svg'),
    },
    mobile: {
      light: PinIconPrimary,
      dark: PinIconPrimaryDark,
    },
  },
  pastIcon: {
    web: {
      light: require('./svg/past-icon.svg'),
      dark: require('./svg/past-icon-dark.svg'),
    },
    mobile: {
      light: PastIcon,
      dark: PastIconDark,
    },
  },
  newspaperIcon: {
    web: {
      light: require('./svg/newspaper.svg'),
      dark: require('./svg/newspaper-dark.svg'),
    },
    mobile: {
      light: NewspaperIcon,
      dark: NewspaperIconDark,
    },
  },
  extremeEventCalendarIcon: {
    web: {
      light: require('./svg/extreme-event-calendar-icon.svg'),
      dark: require('./svg/extreme-event-calendar-icon-dark.svg'),
    },
    mobile: {
      light: ExtremeEventCalendarIcon,
      dark: ExtremeEventCalendarIconDark,
    },
  },
  alertIcon: {
    web: {
      light: require('./svg/alert.svg'),
      dark: require('./svg/alert.svg'),
    },
    mobile: {
      light: AlertIcon,
      dark: AlertIcon,
    },
  },
  alertReadIcon: {
    web: {
      light: require('./svg/alert-read-light.svg'),
      dark: require('./svg/alert-read-dark.svg'),
    },
    mobile: {
      light: AlertReadIcon,
      dark: AlertReadIconDark,
    },
  },
  editIcon: {
    web: {
      light: require('./svg/edit-icon.svg'),
      dark: require('./svg/edit-icon-dark.svg'),
    },
    mobile: {
      light: EditIcon,
      dark: EditIconDark,
    },
  },
  deleteIcon: {
    web: {
      light: require('./svg/delete-icon.svg'),
      dark: require('./svg/delete-icon-dark.svg'),
    },
    mobile: {
      light: DeleteIcon,
      dark: DeleteIconDark,
    },
  },
  lockIcon: {
    web: {
      light: require('./svg/lock-icon.svg'),
      dark: require('./svg/lock-icon-dark.svg'),
    },
    mobile: {
      light: LockIcon,
      dark: LockIconDark,
    },
  },
  checkmarkIcon: {
    web: {
      light: require('./svg/checkmark-icon.svg'),
      dark: require('./svg/checkmark-icon-dark.svg'),
    },
    mobile: {
      light: CheckmarkIcon,
      dark: CheckmarkIconDark,
    },
  },
  checkmarkIconDark: {
    web: {
      light: require('./svg/checkmark-icon-dark.svg'),
      dark: require('./svg/checkmark-icon-dark.svg'),
    },
    mobile: {
      light: CheckmarkIconDark,
      dark: CheckmarkIconDark,
    },
  },
  graduationHatIcon: {
    web: {
      light: require('./svg/graduation-hat-icon.svg'),
      dark: require('./svg/graduation-hat-icon-dark.svg'),
    },
    mobile: {
      light: GraduationHatIcon,
      dark: GraduationHatIconDark,
    },
  },
  girlCommentIcon: {
    web: {
      light: require('./svg/girl-comment-icon.svg'),
      dark: require('./svg/girl-comment-icon-dark.svg'),
    },
    mobile: {
      light: GirlCommentIcon,
      dark: GirlCommentIconDark,
    },
  },
  boySettingsIcon: {
    web: {
      light: require('./svg/boy-settings-icon.svg'),
      dark: require('./svg/boy-settings-icon-dark.svg'),
    },
    mobile: {
      light: BoySettingsIcon,
      dark: BoySettingsIconDark,
    },
  },
  chartsIcon: {
    web: {
      light: require('./svg/charts-icon.svg'),
      dark: require('./svg/charts-icon-dark.svg'),
    },
    mobile: {
      light: ChartsIcon,
      dark: ChartsIconDark,
    },
  },
  fileIcon: {
    web: {
      light: require('./svg/file-icon.svg'),
      dark: require('./svg/file-icon-dark.svg'),
    },
    mobile: {
      light: FileIcon,
      dark: FileIconDark,
    },
  },
  runningShoeIcon: {
    web: {
      light: require('./svg/running-shoe-icon.svg'),
      dark: require('./svg/running-shoe-icon-dark.svg'),
    },
    mobile: {
      light: RunningShoeIcon,
      dark: RunningShoeIconDark,
    },
  },
  locationIcon: {
    web: {
      light: require('./svg/location-icon.svg'),
      dark: require('./svg/location-icon-dark.svg'),
    },
    mobile: {
      light: LocationIcon,
      dark: LocationIconDark,
    },
  },
  uploadIcon: {
    web: {
      light: require('./svg/upload-icon.svg'),
      dark: require('./svg/upload-icon-dark.svg'),
    },
    mobile: {
      light: UploadIcon,
      dark: UploadIconDark,
    },
  },
  filmIconPrimary: {
    web: {
      light: require('./svg/film-icon-primary.svg'),
      dark: require('./svg/film-icon-primary-dark.svg'),
    },
    mobile: {
      light: FilmIconPrimary,
      dark: FilmIconPrimaryDark,
    },
  },
  alertIconLight: {
    web: {
      light: require('./svg/alert-icon.svg'),
      dark: require('./svg/alert-icon.svg'),
    },
    mobile: {
      light: AlertIconLight,
      dark: AlertIconLight,
    },
  },
  tickSuccessIcon: {
    web: {
      light: require('./svg/tick-success.svg'),
      dark: require('./svg/tick-success-dark.svg'),
    },
    mobile: {
      light: TickSuccessIcon,
      dark: TickSuccessIconDark,
    },
  },
  constellationIcon: {
    web: {
      light: require('./svg/constellation-icon.svg'),
      dark: require('./svg/constellation-icon-dark.svg'),
    },
    mobile: {
      light: ConstellationIcon,
      dark: ConstellationIconDark,
    },
  },
  bugIcon: {
    web: {
      light: require('./svg/bug.svg'),
      dark: require('./svg/bug-dark.svg'),
    },
    mobile: {
      light: BugIcon,
      dark: BugIconDark,
    },
  },
  canteenIcon: {
    web: {
      light: require('./svg/canteen-icon.svg'),
      dark: require('./svg/canteen-icon-dark.svg'),
    },
    mobile: {
      light: CanteenIcon,
      dark: CanteenIconDark,
    },
  },
  neptunIcon: {
    web: {
      light: require('./svg/neptun-icon.svg'),
      dark: require('./svg/neptun-icon-dark.svg'),
    },
    mobile: {
      light: NeptunIcon,
      dark: NeptunIconDark,
    },
  },
  momeRunIcon: {
    web: {
      light: require('./svg/momerun-icon.svg'),
      dark: require('./svg/momerun-icon-dark.svg'),
    },
    mobile: {
      light: MomeRunIcon,
      dark: MomeRunIconDark,
    },
  },
  speechBubbleIcon: {
    web: {
      light: require('./svg/speech-bubble-icon.svg'),
      dark: require('./svg/speech-bubble-icon-dark.svg'),
    },
    mobile: {
      light: SpeechBubbleIcon,
      dark: SpeechBubbleIconDark,
    },
  },
  helpIcon: {
    web: {
      light: require('./svg/help-icon.svg'),
      dark: require('./svg/help-icon-dark.svg'),
    },
    mobile: {
      light: HelpIcon,
      dark: HelpIconDark,
    },
  },
  nexonIcon: {
    web: {
      light: require('./svg/nexon-icon.svg'),
      dark: require('./svg/nexon-icon-dark.svg'),
    },
    mobile: {
      light: NexonIcon,
      dark: NexonIconDark,
    },
  },
  schoolIcon: {
    web: {
      light: require('./svg/school-icon.svg'),
      dark: require('./svg/school-icon-dark.svg'),
    },
    mobile: {
      light: SchoolIcon,
      dark: SchoolIconDark,
    },
  },
};

export default svgIcons;
