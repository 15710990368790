import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Pressable, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { isEmpty } from 'lodash';
import { PostFilter, EventCard, Layout, Loader, Pagination } from '../../components';
import { isMobile, isMobileWeb, isTablet, isWeb } from '../../utils/responsive';
import {
  Article,
  GetArticlesParams,
  Order,
  PaginatedResponse,
  Pagination as IPagination,
  PostOrderBy,
} from '../../client/interfaces';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { distributeElements } from '../../utils';
import { cln } from '../../utils/classnames';
import { useResize } from '../../utils/resize';
import { PostFilterScreen } from '../index';

const emptyGetArticlesParams: GetArticlesParams = {
  orderBy: PostOrderBy.publishedAtUtc,
  order: Order.DESC,
  page: 1,
  perPage: 9,
  search: '',
  tags: [],
  isBookmarked: false,
};

const emptyPagination: IPagination = {
  total: 0,
  perPage: 0,
  currentPage: 1,
  pageCount: 1,
  fromNumber: 0,
  toNumber: 0,
};

const ArticlesScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.ArticlesScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const { isWidthBelowSmall, isWidthBelowLarge } = useResize();

  const [isLoading, setIsLoading] = useState(true);
  const [allArticles, setAllArticles] = useState<Article[]>([]);
  const [distributedArticles, setDistributedArticles] = useState<Article[][]>([]);
  const [paginatedArticles, setPaginatedArticles] = useState<Article[]>([]);
  const [pagination, setPagination] = useState<IPagination>(emptyPagination);

  const fallbackTags = route.params?.tags ? route.params.tags : [];
  const normalizedTags = Array.isArray(fallbackTags) ? fallbackTags : [fallbackTags];

  const [getArticlesParams, setGetArticlesParams] = useState<GetArticlesParams>({
    ...emptyGetArticlesParams,
    ...route.params,
    tags: normalizedTags,
  });
  const [nextPageIdentifier, setNextPageIdentifier] = useState<number>(0);
  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);

  const [isPostFilterScreen, setIsPostFilterScreen] = useState(false);

  const flatListRef = useRef<FlatList>(null);

  const getArticlesParamsJSON = JSON.stringify(getArticlesParams);

  const fetchArticles = async (
    afterFetchCallback: (paginatedArticles: PaginatedResponse<Article>) => void,
  ) => {
    try {
      !firstPageLoaded && setFirstPageLoaded(true);
      setIsLoading(true);
      const paginatedArticlesResponse = await client.getArticles(getArticlesParams);
      afterFetchCallback(paginatedArticlesResponse);
      setPagination(paginatedArticlesResponse.pagination);
      setDistributedArticles(
        distributeElements(
          paginatedArticlesResponse.items,
          isWidthBelowSmall ? 1 : isWidthBelowLarge ? 2 : 3,
        ),
      );
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDistributedArticles(
      distributeElements(paginatedArticles, isWidthBelowSmall ? 1 : isWidthBelowLarge ? 2 : 3),
    );
  }, [isWidthBelowLarge, isWidthBelowSmall]);

  const afterMobileFetch = async (paginatedArticles: PaginatedResponse<Article>) => {
    const shouldAppend = getArticlesParams.page === nextPageIdentifier;
    setAllArticles((prev) => [...(shouldAppend ? prev : []), ...paginatedArticles.items]);

    if (!shouldAppend && !isPostFilterScreen) {
      flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
    }
  };

  const afterDesktopFetch = async (paginatedArticles: PaginatedResponse<Article>) => {
    setPaginatedArticles(paginatedArticles.items);
  };

  const toggleBookmark = (article: Article) => {
    const setter = isMobile ? setAllArticles : setPaginatedArticles;

    setter((prev) =>
      prev.map((a) => (a.id === article.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );

    if (!isMobile) {
      setDistributedArticles((prev) =>
        prev.map((row) =>
          row.map((a) => (a.id === article.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
        ),
      );
    }
  };

  const onScrollEndReached = () => {
    const nextPage = pagination.currentPage + 1;

    if (nextPage <= pagination.pageCount) {
      setNextPageIdentifier(nextPage);
    }
  };

  // TODO: nincs használva, kell?
  const emptyArticlesState = (hard = true) => {
    setIsLoading(true);
    hard && setGetArticlesParams({ ...emptyGetArticlesParams });
    setAllArticles([]);
    setPaginatedArticles([]);
    resetInfiniteScroll();
    setPagination(emptyPagination);
    setIsLoading(false);
  };

  const resetInfiniteScroll = () => {
    setNextPageIdentifier(0);
  };

  useEffect(() => {
    isMobile ? fetchArticles(afterMobileFetch) : fetchArticles(afterDesktopFetch);
  }, [getArticlesParamsJSON]);

  useFocusEffect(
    useCallback(() => {
      if (isEmpty(route.params)) {
        isMobile ? fetchArticles(afterMobileFetch) : fetchArticles(afterDesktopFetch);
      }
    }, [route]),
  );

  useEffect(() => {
    if (nextPageIdentifier > pagination.currentPage) {
      setGetArticlesParams((prev) => ({ ...prev, page: nextPageIdentifier }));
    }
  }, [nextPageIdentifier]);

  useEffect(() => {
    setGetArticlesParams((prev) => ({ ...prev, ...route.params, tags: normalizedTags }));
  }, [route.params]);

  // Hide postFilterScreen when navigating to another screen without closing it
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsPostFilterScreen(false);
      // Empty filters when navigating away from the screen
      emptyFilters();
    });
    return unsubscribe;
  }, [navigation]);

  function emptyFilters() {
    setGetArticlesParams((prevState) => ({
      ...prevState,
      tags: [],
      isBookmarked: false,
      search: '',
    }));
  }

  const mobileFeed = (
    <View
      style={{
        marginTop: 24,
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <FlatList
        data={allArticles}
        keyExtractor={(item) => item.id}
        renderItem={(article) => (
          <EventCard
            item={article.item}
            toggleCardMark={toggleBookmark}
            styleProp={{
              flex: isTablet ? 0.5 : 1,
              marginBottom: 40,
            }}
          />
        )}
        onEndReached={onScrollEndReached}
        onEndReachedThreshold={0.8}
        initialNumToRender={emptyGetArticlesParams.perPage}
        ListFooterComponent={isLoading ? Loader : null}
        ref={flatListRef}
        showsVerticalScrollIndicator={false}
        numColumns={isTablet ? 2 : 1}
        columnWrapperStyle={isTablet ? { flex: 1, justifyContent: 'space-between', gap: 24 } : null}
      />
    </View>
  );

  const desktopFeed = isLoading ? (
    <Loader />
  ) : (
    <View>
      <View
        className="flex flex-row flex-wrap md:mx-[24px]"
        style={{
          rowGap: 64,
          columnGap: 24,
          justifyContent: 'flex-start',
          marginTop: isMobileWeb ? 24 : 0,
        }}
      >
        {distributedArticles.map((column, index) => (
          <View className={cln('flex-1')} key={index}>
            {column.map((article) => (
              <EventCard
                key={article.id}
                item={article}
                toggleCardMark={toggleBookmark}
                styleProp={{ marginBottom: isMobile ? 40 : 64 }}
              />
            ))}
          </View>
        ))}
      </View>
      <Pagination data={pagination} paramSetter={setGetArticlesParams} params={getArticlesParams} />
    </View>
  );

  const feed = isMobile ? mobileFeed : desktopFeed;

  const emptySearch = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <>
      {!isLoading && (
        <View className={'flex-column'} style={{ display: isWeb ? 'inline' : 'flex' }}>
          <Text className="text-h3 md:text-h2 text-primaryLight-500 dark:text-primary-500 font-[sans-600]">
            {t('articles:no_results')}
          </Text>
          <Text className="text-h3 md:text-h2 text-neutral-950 dark:text-neutral-50 font-[sans-600]">
            {t('articles:based_on_your_search')}.
          </Text>
        </View>
      )}
    </>
  );
  {
    return isPostFilterScreen ? (
      <PostFilterScreen
        getPostsParams={getArticlesParams}
        setGetPostsParams={setGetArticlesParams}
        resetInfiniteScroll={resetInfiniteScroll}
        allPosts={allArticles}
        screen={ScreenName.ArticlesScreen}
        onPressBackButton={() => setIsPostFilterScreen(false)}
      />
    ) : (
      <Layout
        title={t('articles:title')}
        isScrollView={!isMobile}
        onPressSearch={() => setIsPostFilterScreen(true)}
      >
        {(getArticlesParams?.tags?.length >= 1 ||
          getArticlesParams.isBookmarked ||
          getArticlesParams.search) &&
        !isTablet &&
        isMobile ? (
          <View className="flex-row self-end">
            <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-700] mr-1.5">
              {allArticles.length}
            </Text>
            <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-400]">
              {t('articles:results')}
            </Text>
          </View>
        ) : (
          <></>
        )}
        {/* REMOVE ALL BUTTON */}
        {(getArticlesParams?.tags?.length >= 1 ||
          getArticlesParams.isBookmarked ||
          getArticlesParams.search) &&
        !isTablet &&
        isMobile ? (
          <Pressable
            className="justify-center items-center"
            onPress={emptyFilters}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            <Text className="text-primary-600 dark:text-primary-500 text-body font-[sans-400] py-2">
              {t('articles:remove_search')}
            </Text>
          </Pressable>
        ) : (
          <></>
        )}

        {!isMobile || isTablet ? (
          <PostFilter
            getPostsParams={getArticlesParams}
            setGetPostsParams={setGetArticlesParams}
            resetInfiniteScroll={resetInfiniteScroll}
            allPosts={allArticles}
            pagination={pagination}
            screen={ScreenName.ArticlesScreen}
          />
        ) : (
          <></>
        )}
        {firstPageLoaded && pagination.total === 0 ? emptySearch : feed}
      </Layout>
    );
  }
};

export default ArticlesScreen;
