import { t } from 'i18next';

export function getDateDetails(eventDetails) {
  const monthNames = [
    t('events:months:january'),
    t('events:months:february'),
    t('events:months:march'),
    t('events:months:april'),
    t('events:months:may'),
    t('events:months:june'),
    t('events:months:july'),
    t('events:months:august'),
    t('events:months:september'),
    t('events:months:october'),
    t('events:months:november'),
    t('events:months:december'),
  ];

  const startYear = eventDetails?.startDate?.slice(0, 4);
  const endYear = eventDetails?.endDate?.slice(0, 4);

  //STARTING DATE
  const startDate = eventDetails?.startDate;

  const startMonth = startDate?.slice(5, 7).startsWith('0')
    ? Number(startDate?.slice(6, 7))
    : Number(startDate?.slice(5, 7));

  const startDay = startDate?.slice(8, 10).startsWith('0')
    ? startDate?.slice(9, 10)
    : startDate?.slice(8, 10);

  const startTimeFull = eventDetails?.startTime ? eventDetails?.startTime?.slice(0, 5) : '';
  const startTime = startTimeFull === '00:00' ? '' : startTimeFull;

  // ENDING DATE
  const endDate = eventDetails?.endDate;

  const endDay = endDate?.slice(8, 10).startsWith('0')
    ? endDate?.slice(9, 10)
    : endDate?.slice(8, 10);

  const endMonth = endDate?.slice(5, 7).startsWith('0')
    ? Number(endDate?.slice(6, 7))
    : Number(endDate?.slice(5, 7));

  const endTimeFull = eventDetails?.endTime ? eventDetails?.endTime?.slice(0, 5) : '';
  const endTime = endTimeFull === '00:00' ? '' : endTimeFull;

  const startFinishTime = startTime && endTime ? `${startTime}-${endTime}` : '';

  // Months with words
  const startMonthName = monthNames[startMonth - 1];
  const endMonthName = monthNames[endMonth - 1];

  let dateFirstLine;
  let dateSecondLine;

  // Same year, same month, same day
  if (startMonth === endMonth && startDay === endDay) {
    dateFirstLine = `${startYear}. ${startMonthName} ${startDay}. ${startFinishTime}`;
  }

  if (startMonth === endMonth && startDay !== endDay) {
    dateFirstLine = `${startYear}. ${startMonthName} ${startDay}-${endDay}`;
    dateSecondLine = `${startFinishTime}`;
  }

  if (startMonth !== endMonth) {
    dateFirstLine = `${startYear}. ${startMonthName} ${startDay}. ${startTime}`;
    dateSecondLine = `${endMonthName} ${endDay}. ${endTime}`;
  }

  if (startYear < endYear) {
    dateFirstLine = `${startYear}. ${startMonthName} ${startDay}. ${startTime}`;
    dateSecondLine = `${endYear}. ${endMonthName} ${endDay}. ${endTime}`;
  }

  const fullEndDate = endDate + 'T' + (endTime ? endTime : '23:59:00');

  return {
    dateFirstLine: dateFirstLine || '',
    dateSecondLine: dateSecondLine || '',
    endDate: endDate || '',
    endTime: endTime || '',
    fullEndDate: fullEndDate || '',
    startDate: startDate || '',
    startTime: startTime || '',
  };
}
